exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-customer-service-battery-change-reminder-tsx": () => import("./../../../src/pages/customer-service/battery-change-reminder.tsx" /* webpackChunkName: "component---src-pages-customer-service-battery-change-reminder-tsx" */),
  "component---src-pages-customer-service-contact-us-tsx": () => import("./../../../src/pages/customer-service/contact-us.tsx" /* webpackChunkName: "component---src-pages-customer-service-contact-us-tsx" */),
  "component---src-pages-customer-service-faqs-tsx": () => import("./../../../src/pages/customer-service/faqs.tsx" /* webpackChunkName: "component---src-pages-customer-service-faqs-tsx" */),
  "component---src-pages-customer-service-index-tsx": () => import("./../../../src/pages/customer-service/index.tsx" /* webpackChunkName: "component---src-pages-customer-service-index-tsx" */),
  "component---src-pages-customer-service-keys-replacement-payment-tsx": () => import("./../../../src/pages/customer-service/keys-replacement-payment.tsx" /* webpackChunkName: "component---src-pages-customer-service-keys-replacement-payment-tsx" */),
  "component---src-pages-customer-service-keys-replacement-tsx": () => import("./../../../src/pages/customer-service/keys-replacement.tsx" /* webpackChunkName: "component---src-pages-customer-service-keys-replacement-tsx" */),
  "component---src-pages-customer-service-lost-combination-assistance-tsx": () => import("./../../../src/pages/customer-service/lost-combination-assistance.tsx" /* webpackChunkName: "component---src-pages-customer-service-lost-combination-assistance-tsx" */),
  "component---src-pages-customer-service-lost-combination-payment-tsx": () => import("./../../../src/pages/customer-service/lost-combination-payment.tsx" /* webpackChunkName: "component---src-pages-customer-service-lost-combination-payment-tsx" */),
  "component---src-pages-customer-service-product-manuals-tsx": () => import("./../../../src/pages/customer-service/product-manuals.tsx" /* webpackChunkName: "component---src-pages-customer-service-product-manuals-tsx" */),
  "component---src-pages-customer-service-retailers-promotions-tsx": () => import("./../../../src/pages/customer-service/retailers-promotions.tsx" /* webpackChunkName: "component---src-pages-customer-service-retailers-promotions-tsx" */),
  "component---src-pages-customer-service-videos-tsx": () => import("./../../../src/pages/customer-service/videos.tsx" /* webpackChunkName: "component---src-pages-customer-service-videos-tsx" */),
  "component---src-pages-customer-service-warranty-info-tsx": () => import("./../../../src/pages/customer-service/warranty-info.tsx" /* webpackChunkName: "component---src-pages-customer-service-warranty-info-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-finder-tsx": () => import("./../../../src/pages/product-finder.tsx" /* webpackChunkName: "component---src-pages-product-finder-tsx" */),
  "component---src-pages-product-manuals-tsx": () => import("./../../../src/pages/product-manuals.tsx" /* webpackChunkName: "component---src-pages-product-manuals-tsx" */),
  "component---src-pages-product-registration-tsx": () => import("./../../../src/pages/product-registration.tsx" /* webpackChunkName: "component---src-pages-product-registration-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-replacement-parts-payment-tsx": () => import("./../../../src/pages/replacement-parts-payment.tsx" /* webpackChunkName: "component---src-pages-replacement-parts-payment-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-where-to-buy-tsx": () => import("./../../../src/pages/where-to-buy.tsx" /* webpackChunkName: "component---src-pages-where-to-buy-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog-article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-old-category-url-tsx": () => import("./../../../src/templates/oldCategoryUrl.tsx" /* webpackChunkName: "component---src-templates-old-category-url-tsx" */),
  "component---src-templates-product-detail-tsx": () => import("./../../../src/templates/productDetail.tsx" /* webpackChunkName: "component---src-templates-product-detail-tsx" */)
}

